/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  scannedBarCode = '0';
  articleName = '';
  articleCode = '';
  articleID = '';
  articleImage = '';

  searchValue = '';

  history = [];

  enableCount = false;
  enableStockChange = false;

  constructor() { }

  getScannedBarcode(){
    return this.scannedBarCode;
  }

  saveScannedBarcode(barcode: string){
    this.scannedBarCode = barcode;
  }

  getArticleID(){
    return this.articleID;
  }

  saveArticleID(id: string){
    this.articleID = id;
  }

  saveArticleImage(image: string){
    this.articleImage = image;
  }

  getArticleImage(){
    return this.articleImage;
  }

  getArticleName(){
    return this.articleName;
  }

  saveArticleName(name: string){
    this.articleName = name;
  }

  saveToHistory(article_name: string, article_image: string, location_name: string, stock_change: string, extra_info: string, stock_change_type: string){
    this.history.push({article_name: article_name, article_image: article_image, location_name: location_name, stock_change: stock_change, extra_info: extra_info, stock_change_type: stock_change_type});
  }

  getHistory(){
    return this.history;
  }

  getArticleCode(){
    return this.articleCode;
  }

  saveArticleCode(code: string){
    this.articleCode = code;
  }

  getSearchValue(){
    return this.searchValue;
  }

  saveSearchValue(value: string){
    this.searchValue = value;
  }

  setEnableCount(value: boolean){
    this.enableCount = value;
  }

  getEnableCount(){
    return this.enableCount;
  }

  setEnableStockChange(value: boolean){
    this.enableStockChange = value;
  }

  getEnableStockChange(){
    return this.enableStockChange;
  }

}
